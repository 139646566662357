:root {
	--background-color: #CCC;

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: 1px solid rgba(255, 255, 255, 0.15);
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-bg-color: rgba(255, 255, 255, 0.9);
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);

	--media-devices-any-selected-button-background-color: #D20724;
	--media-devices-any-selected-button-color: white;
	--media-devices-any-selected-button-hover-background-color: #D20724;
	--media-devices-any-selected-button-hover-color: white;

	--media-devices-none-selected-button-background-color: #f50057;
	--media-devices-none-selected-button-color: white;
	--media-devices-none-selected-button-hover-background-color: #f50057;
	--media-devices-none-selected-button-hover-color: white;

	--active-icon-color: #D20724;
	--inactive-icon-color: #AAA;

	--topbar-leave-button-margin: 0;
	--topbar-leave-button-padding: 8px 10px;
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#edumeet
{
	height: 100%;
	width: 100%;
}

.cookieConsent button {
    background-color: #D20724 !important;
    color: white !important;
}

.join-dialog-logo-container {
	max-width: 70%
}

@media (max-width: 768px) {
  .join-dialog-logo-container {
    max-width: 60%
  }
}

.join-dialog-logo-container img {
	max-width: 100%;
}